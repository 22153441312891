import React from "react"
import styled from "styled-components"
import globals from "../components/globals"

import ResponsiveEmbed from "react-responsive-embed"
import BackgroundImage from "gatsby-background-image"

import { P } from "./styledcomponents"

const App = styled(BackgroundImage)`
  width: 100%;
  max-width: ${globals.media.flexBox2};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-bottom: 5em;
`
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22vmin, 1fr));
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 5vmin;
  padding: 0.5em;
  width: 80%;
  margin: 5em auto;
`

const DoorDiv = styled.div`
  width: 20vmin;
  height: 20vmin;
  perspective: 100vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  ${(props) => props.active && `cursor: pointer;`}
  ${(props) =>
    props.open && `box-shadow: inset 0 0 0.5em 0.1em rgba(0, 0, 0, 0.5);`}
  transition: box-shadow 0.2s ease-in-out;
`
const Box = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  transition: transform 1s ease-in-out;
  transform-style: preserve-3d;
  transform-origin: left center;

  ${(props) =>
    props.open &&
    `transition: transform 0.5s ease-in-out; transform: translateY(0%) rotateY(-100deg);`}
`

const Front = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid ${globals.color.main};
  border-radius: 10px;
`
const H3 = styled.h3`
  font-size: 10vmin;
  color: ${globals.color.darkShades};
  text-shadow: 1px 1px 0 white;
  border-radius: 50%;
  background: ${globals.color.main};
  width: 50%;
  height: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

const GiftDiv = styled.a`
  text-shadow: 0.05em 0.05em 0.05em white, -0.05em -0.05em 0.05em white;
  font-size: 12vmin;
  text-decoration: none;
  color: ${globals.color.main};
  ${(props) => !props.open && `visibility: hidden; opacity: 0;`}
  transition-duration: 0.25s;
  transition-property: opacity, visibility;
  transition-delay: 0.25s;
`

const Pop = styled.div`
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: start;
  text-align: center;
  font-size: 2.5em;
  overflow: auto;
  z-index: 2;
  filter: drop-shadow(0 0 5em ${globals.color.darkShades});

  &:target {
    display: flex;
  }
  &:target .dialog {
    animation: scaleIn 0.25s;
    animation-fill-mode: forwards;
  }
`

const Dialog = styled.div`
  position: relative;
  width: 80vw;
  padding: 0.5em;
  margin-top: 3em;
  background: radial-gradient(
    circle at center,
    white,
    ${globals.color.darkAccent}
  );
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 10px;
`

const Video = styled.div`
  width: 100%;
  background: white;
`

const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 1.5rem;
`

const Close = styled.a`
    text-decoration: none;
    border-radius: 30%;
    color: ${globals.color.darkShades};
    border: 0.05em solid ${globals.color.darkShades};
    width: 1.25em;
    height: 1.25em;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: start;
    text-align: right;
    margin-bottom: 0.5em;

    &:hover {
      color: ${globals.color.main};
      background: ${globals.color.darkShades};
    }
  }
`

class Advent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showGift: false,
      days: 0,
    }
  }

  componentDidMount = () => {
    const dat = new Date()

    if (dat.getMonth() + 1 === 12) {
      this.setState({ days: dat.getDate() })
    }
    // ###################
    //uncomment next line to open doors all over the year
    //this.setState({ days: 24 });
  }
  handleChange = (id) => {
    this.setState({ showGift: id })
  }
  render() {
    return (
      <App fluid={this.props.background.childImageSharp.fluid}>
        {console.log(this.props.background)}
        <Container>
          {Object.keys(giftlist).map((item, key) => {
            return (
              <span>
                <Door
                  id={key}
                  day={item}
                  onClick={() => this.handleChange(key)}
                  active={this.state.days >= item.slice(1) ? true : null}
                  open={
                    this.state.days >= item.slice(1) &&
                    this.state.showGift === key
                      ? true
                      : null
                  }
                />
                <Gift
                  id={key}
                  url={giftlist[item].url}
                  title={giftlist[item].title}
                />
              </span>
            )
          })}
        </Container>
      </App>
    )
  }
}

const Emoji = (props) => {
  return (
    <span className="emoji" role="img" aria-label="emoji" aria-hidden="false">
      {String.fromCodePoint(parseInt(props.code, 16))}
    </span>
  )
}
const Gift = (props) => {
  const { id, url, title } = props
  return (
    <Pop id={"door" + id}>
      <Dialog>
        <Title>
          <P margin="0">{title}</P>
          <Close href="#">
            <Emoji code="2715" />
          </Close>
        </Title>
        <Video>
          {true ? (
            <ResponsiveEmbed
              src={`https://www.youtube.com/embed/${url}`}
              allowFullScreen
            />
          ) : undefined}
        </Video>
      </Dialog>
    </Pop>
  )
}

const Door = (props) => {
  const { active, onClick, open, day, id } = props
  return (
    <DoorDiv active={active} open={open}>
      <Box onClick={onClick} open={open}>
        <Front>
          <H3>{day.slice(1)}</H3>
        </Front>
      </Box>
      <GiftDiv href={"#door" + id} tabIndex={id} className="gift" open={open}>
        <Emoji code={xmas[id]} />
      </GiftDiv>
    </DoorDiv>
  )
}

const xmas = [
  "1F384",
  "2764",
  "1F48E",
  "1F56F",
  "1F968",
  "1F369",
  "1F36B",
  "1F36C",
  "1F36D",
  "1F47C",
  "1F385",
  "1F9E6",
  "1F9E3",
  "26F7",
  "1F9E4",
  "26F8",
  "1F6F7",
  "1F98C",
  "1F36A",
  "1F31F",
  "2728",
  "2B50",
  "2604",
  "1F4AB",
  "1F30C",
  "2744",
  "2603",
  "1F381",
  "1F514",
]

const giftlist = {
  d12: {
    url: "R0rKFIY455s",
    title: "Schwebebrücke - Partnerübung",
  },
  d3: {
    url: "AZVY-T0pHFw",
    title: "Navasana: Boot, Solo oder mit Partner",
  },
  d22: {
    url: "GTQOJGOHpbE",
    title: "Kopfstand",
  },
  d6: {
    url: "98rpmAqU_O4",
    title: "Svarga Svijasana - Herausforderung für Dein Gleichgewicht",
  },
  d17: {
    url: "4Ef8con0EdY",
    title: "Jump Back / Jump Through",
  },
  d8: {
    url: "pI5slhf5S7Q",
    title: "Krähe - Armbalance",
  },
  d20: {
    url: "N35bi54GR68",
    title: "Grille - Volle Körperspannung",
  },
  d9: {
    url: "bmeHHwJyA_k",
    title:
      "Flagge II - Fortgeschrittene Partnerübung für Vertrauen und Balance",
  },
  d2: {
    url: "o8d3FH-b7pM",
    title: "Sonnengruß - Surya Namaskar",
  },
  d24: {
    url: "EjPRcS2OyKk",
    title: "Dragon Fly",
  },
  d10: {
    url: "Yw4fyyMi5Og",
    title: "1min Squats",
  },
  d5: {
    url: "oW7_sQda2UE",
    title: "Flagge I - Partneruübeung für Kraft und Balance",
  },
  d16: {
    url: "tqlGov_rwqM",
    title: "Hantel-Swings",
  },
  d1: {
    url: "7zidYeapA6w",
    title: "Der Flieger: Partnerübung für Balance, Vertrauen und Spaß!",
  },
  d19: {
    url: "BzmMfJWW79A",
    title: "Armbalances - Gleichgewicht auf den Armen",
  },
  d15: {
    url: "nR1TNJ7cy9M",
    title: "Schildkröte",
  },
  d21: {
    url: "1tQQqSinMqI",
    title: "Shoulder Press Asana",
  },
  d11: {
    url: "X-ZTRnxwVus",
    title: "Side Crow - Armbalance",
  },
  d7: {
    url: "gM6HP4OIqko",
    title: "Pistol Squats",
  },
  d14: {
    url: "m2Tr98RVCNg",
    title: "Uppercuts - Core und Hüfttraining",
  },
  d23: {
    url: "fKb2T7lzxqI",
    title: "Armbalance",
  },
  d4: {
    url: "dc7BQ0gvgwk",
    title: "Wall Squat - Ausdauer in den Beinen",
  },
  d18: {
    url: "40-dgepJsA0",
    title: "Schulter-Schulter-Rolle",
  },
  d13: {
    url: "PDo8BBMiwXg",
    title: "Utpluthi - 'Sitzend schweben'",
  },
}

export default Advent
