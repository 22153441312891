import React from "react"
import {graphql } from "gatsby"
import Advent from "../components/advent"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import globals from "../components/globals"

import { FlexBox2, P } from "../components/styledcomponents"

const H1 = styled.h1`
  text-align: center;
  color: ${globals.color.darkShades};
  text-shadow: -0.05em 0.05em 0.05em ${globals.color.main};
`

const Adventskalender = ({ data }) => (
  <Layout>
    <SEO
      title="Adventskalender 2021"
      description="Den ganzen Advent täglich eine neue Challenge!"
    />
    <H1>Adventskalender 2021</H1>
    <FlexBox2
      maxWidth={globals.media.tablet}
      justifyContent="left"
      padding="1em"
    >
      <P left>
        In unserem Adventskalender kannst Du jeden Tag ein Türchen öffnen.
      </P>
      <P left>
        Hinter jedem Türchen verbirgt sich eine kleine Überraschung und dahinter
        eine neue Challenge! Eine Minute reicht für die meisten Challenges.
      </P>
      <P left>
        Wenn Du eine Challenge gemacht habt freuen wir uns zu höheren, wenn Du
        sie gemacht hast. Schreib uns, schick uns ein Video oder tagge uns auf
        Sozial Media mit dem Hashtag: <strong>#tlaadventchallenge</strong>
      </P>
    </FlexBox2>
    <Advent background={data.background} />
  </Layout>
)

export const query = graphql`
  query {
    background: file(
      relativePath: { eq: "Yuki/Adventskalender-Yuki-Weihnachten.JPG" }
    ) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default Adventskalender
